<template>
  <div class="dev-center">
    <DevHeader />
    <DevBody />
  </div>
</template>

<script>
import "./dev.scss";
export default {
  components: {
    DevHeader: () => import("./DevHeader.vue"),
    DevBody: () => import("./DevBody.vue"),
  },
};
</script>
